











import Vue from 'vue';
import EmailVerify from '@/components/auth/EmailVerify.vue';

export default Vue.extend({
  components: {
    EmailVerify,
  },
});
