






















































import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      error: null,
      isLoading: false,
      isVerified: false,
    };
  },
  methods: {
    async verify() {
      const { id, secret } = this.$route.query;

      this.isLoading = true;

      const success = await this.$store.dispatch('VerifyEmail', {
        EMAIL_ID: id,
        form: {
          secret,
        },
      }).catch((e) => { this.error = e; });

      this.isLoading = false;
      this.isVerified = success || false;
    },
  },
});
